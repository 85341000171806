import React, { Component } from "react";
import { operations, colors } from "../../Utils/appVars";
import "../../styles/trophies.css";

import levels_sum from "../../images/sum_levels.png";
import levels_sub from "../../images/subtraction_levels.png";
import levels_mult from "../../images/multiplication_levels.png";
import levels_div from "../../images/division_levels.png";
import levels_perc from "../../images/percentage_levels.png";
import levels_gen from "../../images/general_levels.png";
import levels_podium from "../../images/podium_levels.png";
import icon_sum from "../../images/sum_icon.svg";
import icon_subtraction from "../../images/subtraction_icon.svg";
import icon_multiplication from "../../images/multiplication_icon.svg";
import icon_division from "../../images/division_icon.svg";
import icon_percentage from "../../images/percentage_icon.svg";
import icon_general from "../../images/general_icon.svg";
import icon_podium from "../../images/podium_icon.svg";

const opsArr = operations.concat(["general", "podium"]);
const badgesArr = [levels_sum, levels_sub, levels_mult, levels_div, levels_perc, levels_gen, levels_podium];
const iconsArr = [icon_sum, icon_subtraction, icon_multiplication, icon_division, icon_percentage, icon_general, icon_podium];

class Trophies extends Component {
  state = {
    index: 0
  };

  componentDidMount() {
    const scrollItem = document.querySelector(".js-badges-list");
    scrollItem.addEventListener("scroll", this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    const scrollItem = document.querySelector(".js-badges-list");
    scrollItem.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event) {
    let scrollTop = event.srcElement.scrollTop;
    var scrollIndex = Math.round(scrollTop / 260);

    this.setState({
      index: scrollIndex
    });
  }

  scrollToEl = i => {
    const scrollItem = document.querySelector(".js-badges-list");
    const top = i * 260;
    scrollItem.scroll({ top, left: 0, behavior: "smooth" });
    this.setState({ index: i });
  };

  renderBadges() {
    return opsArr.map((v, i) => {
      return (
        <li key={i} className="badges__item pv-xl">
          <h3 className="mb-l f4 fw-300 capitalize">{v} Badges</h3>
          <img className="badges__img" src={badgesArr[i]} alt={`${v} Badges`} />
        </li>
      );
    });
  }

  renderIcons() {
    const { index } = this.state;
    const iconColors = colors.concat(["red", "red-dark"]);

    return opsArr.map((v, i) => {
      const active = i === index;

      return (
        <li key={i} className="flex items-center justify-center badges-nav__item">
          <button
            title={`Select ${v} badges`}
            name={`Select ${v} badges`}
            className={`icon-badges_sum db tc-white badges-nav__link badges-nav__${iconColors[i]} td-60 ${active && "active"}`}
            onClick={() => this.scrollToEl(i)}
          >
            <img className="db mh-auto badges-nav__icon" src={iconsArr[i]} alt={`${v} icon`} />
          </button>
        </li>
      );
    });
  }

  render() {
    return (
      <article className="js-trophies mb-l-s pv-l pv-xl-l pb-xl-s section-border-s fadeup">
        <div className="flex flex-column-reverse-s items-center">
          <div className="relative w-p-100 w-p-50-ns mh-auto-s pr-l-m tc td-80 fadeup__item">
            <div className="of-hidden">
              <ul className="js-badges-list badges of-scroll">{this.renderBadges()}</ul>
            </div>
            <ul className="absolute badges-nav">{this.renderIcons()}</ul>
          </div>
          <div className="flex justify-center w-p-100 w-p-50-ns pl-l-m td-80 fadeup__item">
            <div className="mxw-400 tc-s">
              <h2 className="mb-l f3 f2-l fw-300">Trophy room.</h2>
              <p className="mxw-400 mb-l-ns f-text f4-l lh1 tc-grey-light">
                As you progress through the different levels of each calculation type you will collect different trophies. Here
                you can see the available trophies and how to get them.
              </p>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Trophies;
