const generateNumber = digits => Math.floor(Math.random() * (9 * digits) + 1 * digits);

const getRandomArbitrary = (min, max) => Math.ceil(Math.random() * (max - min) + min);

const returnTwoDecimalsresult = (number, percentage) => Math.round(number * (percentage / 100) * 100) / 100;

const evil = fn => new Function("return " + fn)();

const checkRepeatedOperation = (prevOps, level, operation, result, cb, type) => {
  const prevOpsLength = prevOps.length;
  let isFinished = false;
  let foundCopy = false;
  let newData = {};

  if (prevOpsLength > 0) {
    prevOps.forEach((value, i) => {
      if (value === operation) {
        foundCopy = true;
        newData = cb(prevOps, level, type);
      }
      isFinished = i + 1 === prevOpsLength;
    });
  } else {
    isFinished = true;
  }

  if (isFinished) {
    return foundCopy ? newData : { operation, result };
  }
};

export const generateCalculation = (prevOps, level, type) => {
  const levelDigits = [[1, 1], [10, 1], [10, 10], [100, 10], [100, 100]];
  const operations = {
    sum: "+",
    subtraction: "-",
    multiplication: "x"
  };
  const firstNumber = generateNumber(levelDigits[level][0]);
  const secondNumber = generateNumber(levelDigits[level][1]);
  const operator = operations[type];
  const calculationOperator = operator === "x" ? "*" : operator;
  let operation;
  let result;
  if (operator === "-") {
    if (firstNumber === secondNumber) {
      operation = firstNumber + 1 + " - " + secondNumber;
    } else {
      operation = firstNumber < secondNumber ? secondNumber + " - " + firstNumber : firstNumber + " - " + secondNumber;
    }
    result = evil(operation);
  } else {
    operation = firstNumber + " " + operator + " " + secondNumber;
    result = evil(firstNumber + calculationOperator + secondNumber);
  }

  return checkRepeatedOperation(prevOps, level, operation, result, generateCalculation, type);
};

export const generatePercentage = (prevOps, level) => {
  const percentagesArr = [5, 10, 20, 25, 50, 15, 30, 40, 60];
  let percentage, number, index;

  if (level === 4) {
    percentage = getRandomArbitrary(1, 100);
    number = getRandomArbitrary(1, 20) * 10;
  } else {
    index = level === 0 || level === 2 ? getRandomArbitrary(0, 4) : getRandomArbitrary(0, 8);
    percentage = percentagesArr[index];
    number = level === 0 || level === 1 ? getRandomArbitrary(1, 20) * 10 : getRandomArbitrary(1, 20) * 5;
  }

  const operation = percentage + "% of " + number;
  const result = returnTwoDecimalsresult(number, percentage);
  return checkRepeatedOperation(prevOps, level, operation, result, generatePercentage);
};

export const generateDivision = (prevOps, level) => {
  let divisorNumber, resultNumber;

  if (level === 0) {
    divisorNumber = getRandomArbitrary(2, 6);
    resultNumber = getRandomArbitrary(2, 6);
  } else if (level === 1) {
    divisorNumber = getRandomArbitrary(2, 12);
    resultNumber = getRandomArbitrary(2, 12);
  } else if (level === 2) {
    divisorNumber = getRandomArbitrary(3, 24);
    resultNumber = getRandomArbitrary(3, 24);
  } else if (level === 3) {
    divisorNumber = getRandomArbitrary(6, 36);
    resultNumber = getRandomArbitrary(6, 36);
  } else {
    divisorNumber = getRandomArbitrary(6, 48);
    resultNumber = getRandomArbitrary(6, 48);
  }

  const dividedNumber = divisorNumber * resultNumber;
  const operation = dividedNumber + " / " + divisorNumber;
  const result = resultNumber;

  return checkRepeatedOperation(prevOps, level, operation, result, generateDivision);
};
