import React, { Component } from "react";
import UIfx from "uifx";
import { generatePercentage, generateDivision, generateCalculation } from "../../../Utils/gameEngine";
import { levels } from "../../../Utils/appVars";
import correct from "../../../sounds/correct.mp3";

class ScreenGame extends Component {
  level = levels.indexOf(this.props.level);
  beep = new UIfx(correct);
  state = {
    answerIndex: 1,
    gameEnded: false,
    resetCounter: false,
    currentPoints: 0,
    points: 0,
    operation: "",
    prevOperations: [],
    time: 8000 + this.level * 2000,
    result: 0,
    correctAnswer: false
  };

  componentDidMount() {
    const { operation, result, prevOperations } = this.setOperation();

    // this.sound = new Sound("correct.mp3", Sound.MAIN_BUNDLE, error => {
    //   if (error) {
    //     console.log("failed to load the sound", error);
    //     return;
    //   }
    // });

    this.setState({
      operation,
      result,
      prevOperations
    });
    this.startTimer();
  }

  componentDidUpdate() {
    const { nextScreen, setPoints } = this.props;
    const { gameEnded, points } = this.state;

    if (gameEnded) {
      this.stopTimer();
      nextScreen();
      setPoints(points);
    }
  }

  componentWillUnmount() {
    this.stopTimer();
    this.feedbackTimeout = null;
  }

  setOperation() {
    const { type } = this.props;
    const { prevOperations } = this.state;
    let data = {};

    if (type === "percentage") {
      data = generatePercentage(prevOperations, this.level);
    } else if (type === "division") {
      data = generateDivision(prevOperations, this.level);
    } else {
      data = generateCalculation(prevOperations, this.level, type);
    }

    const { operation, result } = data;
    prevOperations.push(operation);
    return { operation, result, prevOperations };
  }

  startTimer() {
    const { time } = this.state;
    this.startDate = Date.now();
    this.timer = setInterval(() => {
      this.setState({ gameEnded: true });
    }, time);
    document.querySelector(".js-time-bar").className += " bar-active";
    document.querySelector(".js-points-feedback").classList.remove("active");
  }

  stopTimer() {
    clearInterval(this.timer);
  }

  getFinalTime() {
    const endDate = Date.now();
    const finalTime = Math.trunc((endDate - this.startDate) / 100);

    return finalTime;
  }

  calculateOpPoints(time) {
    const levelTime = 20 + this.level * 10;
    const currentPoints = time <= levelTime ? 10 : Math.round(10 - ((time - levelTime) * 1.6) / 10);

    return currentPoints;
  }

  calculateTotalPoints(currentPoints) {
    const { points } = this.state;

    return points + currentPoints;
  }

  keyPress(event) {
    const { type } = this.props;
    const { result, answerIndex } = this.state;
    const value = event.target.value;

    if (value === "." && value.length === 0) {
      return;
    }
    if (type !== "division" && value === 0 && value.length === 0) {
      return;
    }

    const newInput = parseFloat(value);
    const correctAnswer = newInput === result;

    if (correctAnswer) {
      const { operation, result, prevOperations } = this.setOperation();
      const time = this.getFinalTime();
      const currentPoints = this.calculateOpPoints(time);
      const points = this.calculateTotalPoints(currentPoints);
      document.querySelector(".js-time-bar").classList.remove("bar-active");
      document.querySelector(".js-points-feedback").className += " active";

      this.beep.play();
      this.setState({ correctAnswer: true, currentPoints });
      this.feedbackTimeout = setTimeout(() => {
        this.stopTimer();
        this.setState({
          operation,
          result,
          prevOperations,
          answerIndex: answerIndex + 1,
          resetCounter: true,
          correctAnswer: false,
          gameEnded: answerIndex === 10,
          points
        });

        if (answerIndex < 10) {
          this.startTimer();
          this.inputEntry.value = "";
        }
      }, 600);
    } else {
      this.setState({ resetCounter: false });
    }
  }

  render() {
    const { color } = this.props;
    const { time, correctAnswer, answerIndex, operation, currentPoints } = this.state;
    const td = time / 1000 + "s";
    let numberColor;

    if (currentPoints >= 8) {
      numberColor = "#336643";
    } else if (currentPoints < 5) {
      numberColor = "#cc5252";
    } else {
      numberColor = "#f7931e";
    }

    return (
      <section className="flex flex-column justify-center items-center w-p-100">
        <div className="flex items-center flex-1">
          <div className="relative timer">
            <span
              className={`js-time-bar absolute top-0 left-0 w-p-100 bg-${color} h-p-100`}
              style={{ transitionDuration: !correctAnswer && td }}
            />
          </div>
        </div>
        <div className="relative flex justify-center flex-column flex-3 mb-l">
          <p className="js-points-feedback absolute fw-600 points-feedback" style={{ color: numberColor }}>
            +{currentPoints}
          </p>
          <p className="mb-l f2 fw-100 tc">{operation}</p>
          <input
            className="game-input tc f3"
            type="number"
            ref={el => (this.inputEntry = el)}
            autoFocus={true}
            onChange={e => this.keyPress(e)}
          />
        </div>
        <p className="fw-300">{answerIndex}/10</p>
      </section>
    );
  }
}

export default ScreenGame;
