import React from "react";
import "../../styles/design-wireframes.css";
import ranking from "../../images/wireframe.png";

const Wireframes = () => (
  <article className="js-wireframes flex flex-wrap justify-center justify-end-l h-480 h-580-ns pv-l-nl p-xl-l section-border-s fadeup">
    <div className="w-p-100 w-p-50-l mxw-450-nl tc-nl td-80 fadeup__item">
      <h4 className="mb-l f3 f2-ns fw-300">Wireframes</h4>
      <p className="mb-l f-text f4-l lh1 tc-grey-light">
        Since the purpose of the app is the mental calculation training, the design was thought to be as minimalistic as
        possible. Having only the absolute necessary screens for users to practice, follow their progression and compare
        results.
      </p>
    </div>
    <img className="w-p-100 wf-image" src={ranking} alt="Math Arena ranking" />
  </article>
);

export default Wireframes;
