import React from "react";
import screens from "../../images/screens.png";

const Intro = () => (
  <article className="js-intro pv-l pv-xl-l section-border-s fadeup">
    <div className="flex-ns items-center">
      <div className="flex justify-center w-p-100 w-p-50-ns pr-l-m td-60 fadeup__item">
        <div className="mxw-450 tc tl-ns">
          <h2 className="mb-l f3 f2-l fw-300">Want to learn tips to calculate faster?</h2>
          <p className="mxw-400 mb-m f-text f4-l lh1 tc-grey-light">
            Math Arena is an app that not only helps you practice your mental arithmetic skills but also teaches you ways to
            calculate faster.
          </p>
        </div>
      </div>
      <div className="flex-l justify-center w-p-100 w-p-50-ns tc td-60 fadeup__item">
        <img className="w-p-100 mxw-380" src={screens} alt="Math Arena app screen" />
      </div>
    </div>
  </article>
);

export default Intro;
