import React, { Component } from "react";
import screen from "../../images/MA_screen1.png";
import Modal from "./Modal";

class Game extends Component {
  state = {
    gameOn: false
  };

  toggleGameModal() {
    const { gameOn } = this.state;

    if (!gameOn) {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
    this.setState({ gameOn: !gameOn });
  }

  render() {
    const { gameOn } = this.state;

    return (
      <article className="js-game pv-l pv-xl-l section-border-s fadeup">
        <div className="js-animated-item flex-ns items-center">
          <div className="dn-s w-p-100 w-p-50-ns mh-auto-s tc td-80 fadeup__item">
            <img className="w-180" src={screen} alt="Math Arena app Screen" />
          </div>
          <div className="flex justify-center w-p-100 w-p-50-ns td-80 fadeup__item">
            <div className="mxw-400 tc-s">
              <h2 className="mb-l f3 f2-l fw-300">Don’t use the calculator machine, become one.</h2>
              <p className="mxw-400 mb-l f-text f4-l lh1 tc-grey-light">
                Practice makes perfect. With Math Arena you can work on your Sum, Multiplication, Division, Subtraction and
                Percentages calculation skills. Begin with the basic calculations and advance as you get better and better. For
                each calculation type there are 5 levels.
              </p>
              <button onClick={() => this.toggleGameModal()} className="dib f5 fw-600 tc-white uppercase bg-red td-40 btn">
                Test your skills here.
              </button>
            </div>
          </div>
        </div>
        <Modal gameOn={gameOn} toggleGameModal={() => this.toggleGameModal()} />
      </article>
    );
  }
}

export default Game;
