import React from "react";
import facebook from "../images/icon-fb.svg";
import instagram from "../images/icon-ig.svg";
// import linkedin from "../images/icon-in.svg";
// import twitter from "../images/icon-tt.svg";

const Footer = () => (
  <footer className="w-p-100 pv-l bg-red-gradient">
    <div className="mxw-1212 flex flex-column-nl justify-between items-center mh-auto ph-m tc-white">
      <div className="flex flex-column-nl mb-l-nl">
        <p className="mb-s-nl mr-s-l fw-600">
          <em>Developed by:</em>
        </p>
        <a href="https://formosastudios.net/" className="fw-300 u-default" target="_blank" rel="noopener noreferrer">
          Formosa Studios
        </a>
      </div>
      <div className="flex flex-column-nl items-center">
        <p className="mr-m-l mb-s-nl fw-600">
          <em>Follow us on:</em>
        </p>
        <div className="flex">
          <a href="https://www.facebook.com/matharenaapp/" className="mr-m" target="_blank" rel="noopener noreferrer nofollow">
            <img src={facebook} className="va-middle" alt="Math Arena facebook page" />
          </a>
          <a href="https://www.instagram.com/math_arena/" className="mr-m" target="_blank" rel="noopener noreferrer nofollow">
            <img src={instagram} className="va-middle" alt="Math Arena instagram page" />
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
