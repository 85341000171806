import React, { Component } from "react";
import "../../styles/cover.css";
import android from "../../images/play-store.png";
import ios from "../../images/app-store.png";
import cover_phone from "../../images/MA_iphone.png";

class Cover extends Component {
  state = {
    active: false
  };

  componentDidMount() {
    this.setState({ active: true });
  }

  render() {
    const { active } = this.state;

    return (
      <section className="bg-red-gradient tc-white of-hidden">
        <div className="flex-ns items-center mxw-1212 vh-100 mxh-640 mh-auto ph-m cover">
          <div className="flex justify-center-s justify-end w-p-100 w-p-50-ns pr-xl-l tc-s">
            <div className="mxw-450 mh-auto-s">
              <h2 className="f1 mb-m mb-l-ns fw-300">Math Arena</h2>
              <h1 className="mb-l mb-xl-ns f4 f3-m f2-l fw-300 fw-100-l">
                Improve your arithmetic skills and learn how to calculate large numbers faster.
              </h1>
              <div className="flex justify-center-s">
                <a
                  href="https://play.google.com/store/apps/details?id=com.matharenalr"
                  target="blank"
                  rel="noopener noreferrer nofollow"
                  className="h-42 h-50-l mr-m"
                >
                  <img className="mxh-p-100" src={android} alt="Download Math Arena on Play Store" />
                </a>
                <a
                  href="https://apps.apple.com/us/app/math-arena/id1451707900?l=pt&ls=1"
                  target="blank"
                  rel="noopener noreferrer nofollow"
                  className="h-42 h-50-l"
                >
                  <img className="mxh-p-100" src={ios} alt="Download Math Arena on App Store" />
                </a>
              </div>
            </div>
          </div>
          <div className="relative w-p-100 w-p-50-ns h-p-100">
            <img
              className={`absolute w-p-100 mxw-400-s td-40 header-img ${active && "active"}`}
              src={cover_phone}
              alt="Math Arena iPhone screen"
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Cover;
