import React from "react";

import "../../styles/cs-cta.css";

import screen1 from "../../images/cs-screen-1.jpg";
import screen2 from "../../images/cs-screen-2.jpg";
import screen3 from "../../images/cs-screen-3.jpg";
import logo from "../../images/math-arena-logo.png";
import android from "../../images/play-store.png";
import ios from "../../images/app-store.png";

const Cta = () => (
  <article className="js-cta mxw-1212 mh-auto pv-xl ph-m of-hidden fadeup">
    <div className="mb-xl tc">
      <h3 className="relative dib pb-m-l f2 f1-l tc u-regular-title">Final result</h3>
    </div>
    <div className="flex flex-column-nl flex-column-reverse-nl items-center">
      <div className="w-p-100 w-p-50-l mxw-690-nl mb-l-s mb-xl-m pr-xl-l tc-nl td-80 fadeup__item">
        <img className="dn-nl mb-m va-middle cs-cta-logo" src={logo} alt="Math Arena logo" />
        <p className="mb-l f-text f4-l lh1 tc-grey-light">
          In the end the result was pretty much what was planned and idealized from the beginning. Of corse it's not perfect yet
          and there are many updates on the way. Work in improving the app will continue in order to provide a better experience
          and help its users to become better and better at their mental math.
          <br />
          <br /> Feel free to check the app and give your review. Any questions you can contact us directly on{" "}
          <a href="mailto:support@matharena.info" className="fw-600 tc-red u-default">
            support@matharena.info
          </a>
          .
        </p>
        <div className="flex justify-center mt-xl">
          <a
            href="https://play.google.com/store/apps/details?id=com.matharenalr"
            target="blank"
            rel="noopener noreferrer nofollow"
            className="h-42 mr-l"
          >
            <img className="mxh-p-100" src={android} alt="Download Math Arena on Play Store" />
          </a>
          <a
            href="https://apps.apple.com/us/app/math-arena/id1451707900?l=pt&ls=1"
            target="blank"
            rel="noopener noreferrer nofollow"
            className="h-42"
          >
            <img className="mxh-p-100" src={ios} alt="Download Math Arena on App Store" />
          </a>
        </div>
      </div>
      <div className="w-p-100 w-p-50-l mxw-380 mh-auto mb-l-nl tc td-80 fadeup__item">
        <ul className="relative cs-cta-screens">
          <li className="absolute">
            <img className="w-p-100" src={screen1} alt="Math Arena screen" />
          </li>
          <li className="absolute">
            <img className="w-p-100" src={screen2} alt="Math Arena screen" />
          </li>
          <li className="absolute">
            <img className="w-p-100" src={screen3} alt="Math Arena screen" />
          </li>
        </ul>
      </div>
    </div>
  </article>
);

export default Cta;
