import React from "react";

const ScreenPreGame = ({ type, level, color, nextScreen }) => (
  <section className="flex flex-column justify-center items-center w-p-100">
    <h3 className="f3 f2-ns fw-100 tc-grey-light mb-l">You selected:</h3>
    <div className="flex justify-around w-p-100 mxw-380 mb-xl">
      <div className="tc">
        <p className="mb-m f4 tc-grey-light">Operation:</p>
        <p className={`capitalize f4 fw-600 tc-${color}`}>{type}</p>
      </div>
      <div className="tc">
        <p className="mb-m f4 tc-grey-light">Level:</p>
        <p className={`capitalize f4 fw-600 tc-${color}`}>{level}</p>
      </div>
    </div>
    <button
      title="start game"
      className={`dib f5 bg-${color} tc-white fw-600 uppercase td-40 btn`}
      onClick={() => nextScreen()}
    >
      Start Game
    </button>
  </section>
);

export default ScreenPreGame;
