import React from "react";
import iosIcon from "../../images/app-store-icon.svg";
import androidIcon from "../../images/play-store-icon.svg";

const Overview = () => (
  <article className="js-overview pv-l pv-xl-l ph-m-s ph-l section-border-s fadeup">
    <div className="w-p-100 mxw-690 mh-auto tc-s td-80 fadeup__item">
      <h2 className="mb-l f3 f2-l fw-300">Overview</h2>
      <p className="mb-xl f-text f4-l lh1 tc-grey-light">
        As technology evolves and life gets easier, our brains get more and more out of shape. The idea to build this app came
        as an attempt to prevent that, by helping people do a little bit of brain workout in a useful and engaging way.
        <br /> The main focus of the app was to help people improve their mental mathematical calculation skills at the same
        time that they kept their brains working and healthy. And because any kind of workout is hard and requires some
        motivation, a competitive aspect was added. It consists in awarding different badges as users progress through the
        different levels and by having an overall ranking where users can compare their results with each other.
        <br />
        <br />
        The goal was to create a simple app to use, with a clean and intuitive design that would be available both on iOS and
        Android.
      </p>
      <div className="flex justify-around w-p-100 mxw-380 mh-auto">
        <a
          href="https://play.google.com/store/apps/details?id=com.matharenalr"
          target="blank"
          rel="noopener noreferrer nofollow"
          className="tc"
        >
          <img className="h-42 h-50-l mxh-p-100 mb-s" src={iosIcon} alt="Download Math Arena on Play Store" />
          <p className="f5 tc-red">Visit on Play Store</p>
        </a>
        <a
          href="https://apps.apple.com/us/app/math-arena/id1451707900?l=pt&ls=1"
          target="blank"
          rel="noopener noreferrer nofollow"
          className="tc"
        >
          <img className="h-42 h-50-l mxh-p-100 mb-s" src={androidIcon} alt="Download Math Arena on App Store" />
          <p className="f5 tc-red">Visit on App Store</p>
        </a>
      </div>
    </div>
  </article>
);

export default Overview;
