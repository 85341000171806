import React, { Component } from "react";
import Cover from "./Cover";
import Overview from "./Overview";
import Wireframes from "./DesignWireframes";
import Logo from "./DesignLogo";
import Badges from "./DesignBadges";
import TypoColors from "./DesignTypoColors";
import CodeIntro from "./CodeLanguage";
import CodeServer from "./CodeServer";
import Cta from "./Cta";

import separator from "../../images/case-study-separator.png";

class CaseStudy extends Component {
  componentDidMount() {
    const { setTopsArr, setSections } = this.props;
    const windowHeight = window.innerHeight;
    const tops = [];
    const sections = [
      ".js-overview",
      ".js-wireframes",
      ".js-typoColors",
      ".js-logo",
      ".js-badges",
      ".js-server",
      ".js-codeIntro",
      ".js-cta"
    ];

    sections.forEach((v, i) => {
      tops.push(document.querySelector(v).getBoundingClientRect().top + windowHeight / 2);
    });

    setTopsArr(tops);
    setSections(sections);
  }

  render() {
    return (
      <main className="tc-grey-dark">
        <Cover />
        <Overview />
        <section className="bg-grey-light">
          <article className="mxw-1212 mh-auto pv-xl ph-m of-hidden">
            <h3 className="mb-m mb-l-m mb-xl-l f2 f1-l tc">Design</h3>
            <Wireframes />
            <TypoColors />
            <Logo />
            <Badges />
          </article>
        </section>
        <div className="h-180 h-280-m h-420-l bg-blue bg-cover bg-center" style={{ backgroundImage: `url(${separator})` }} />
        <article className="mxw-1212 mh-auto pv-xl ph-m of-hidden">
          <h3 className="mb-m mb-l-m mb-xl-l f2 f1-l tc">Code</h3>
          <CodeIntro />
          <CodeServer />
        </article>
        <section className="bg-grey-light">
          <Cta />
        </section>
      </main>
    );
  }
}

export default CaseStudy;
