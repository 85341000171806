import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import logo from "../images/math-arena-logo.svg";
import logoRed from "../images/math-arena-logo-red.svg";
import facebook from "../images/icon-fb-red.svg";
import instagram from "../images/icon-ig-red.svg";
import linkedin from "../images/icon-in-red.svg";
import twitter from "../images/icon-tt-red.svg";

import "../styles/navigation.css";

class Navigation extends Component {
  state = {
    opened: false
  };

  toggleMenu() {
    const { opened } = this.state;
    this.setState({ opened: !opened });
  }

  render() {
    const { location, bgRed } = this.props;
    const { opened } = this.state;

    return (
      <header className={`fixed top-0 left-0 z-2 w-p-100 ${bgRed && "bg-red"} td-40`}>
        <nav className="mxw-1212 flex justify-between items-center mh-auto ph-m f-title">
          <Link className="relative z-1" to="/">
            <img src={opened ? logoRed : logo} className="logo" alt="Math Arena logo" />
          </Link>
          <button
            onClick={() => this.toggleMenu()}
            className={`relative z-1 dn-l menu-icon ${opened ? "menu-icon--dark close" : "menu-icon--white"} `}
          >
            <span />
          </button>
          <ul
            className={`relative-l absolute top-0 left-0 flex justify-center items-center flex-column-nl w-p-100-nl vh-100-nl f4 tc-red tc-white-l bg-white-nl td-60 nav-menu ${opened &&
              "nav-menu--opened"}`}
          >
            <li className="mr-l-l mb-l-nl">
              <a
                className="relative nav-link"
                href="https://www.quickmathsblog.com"
                target="_blank"
                rel="noopener noreferrer"
                data-hover="Quick Maths Blog"
              >
                Quick Maths Blog
              </a>
            </li>
            <li className="mb-xl-nl">
              <Link
                className={`relative nav-link ${location.pathname === "/case-study" && "active"}`}
                to="/case-study"
                data-hover="Case Study"
              >
                Case Study
              </Link>
            </li>
            <li className="dn-l">
              <p className="mb-m fw-600 tc">
                <em>Follow us on:</em>
              </p>
              <div className="flex">
                <a href="https://www.facebook.com" className="mr-m" target="_blank" rel="noopener noreferrer nofollow">
                  <img src={facebook} className="va-middle" alt="Math Arena facebook page" />
                </a>
                <a href="https://www.facebook.com" className="mr-m" target="_blank" rel="noopener noreferrer nofollow">
                  <img src={instagram} className="va-middle" alt="Math Arena instagram page" />
                </a>
                <a href="https://www.facebook.com" className="mr-m" target="_blank" rel="noopener noreferrer nofollow">
                  <img src={linkedin} className="va-middle" alt="Math Arena linkedin page" />
                </a>
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer nofollow">
                  <img src={twitter} className="va-middle" alt="Math Arena twitter page" />
                </a>
              </div>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

export default withRouter(Navigation);
