import React from "react";
import { Link } from "react-router-dom";
import "../../styles/home-case-study.css";
import screen1 from "../../images/math_arena_case_study_screen_1.png";
import screen2 from "../../images/math_arena_case_study_screen_2.png";
import screen3 from "../../images/math_arena_case_study_screen_3.png";

const Ranking = () => (
  <article className="js-case-study pt-l pt-xl-l fadeup">
    <div className="flex-ns flex-column-s items-center">
      <div className="flex justify-center w-p-100 w-p-50-ns td-80 fadeup__item">
        <div className="mxw-400 tc-s">
          <h2 className="mb-l f3 f2-l fw-300">Case study</h2>
          <p className="mxw-400 mb-l f-text f4-l lh1 tc-grey-light">
            If you want to know what was the process that lead to the creation of this app, you can read about it{" "}
            <Link className="tc-red u-default" to="/case-study">
              here
            </Link>
            .
          </p>
        </div>
      </div>
      <div className="relative w-p-100 w-p-50-ns mh-auto-s dynamic-screens">
        <div className="absolute-s center-elem-x-s w-460-s">
          <img className="absolute w-p-100 dynamic-screen" src={screen3} alt="Math Arena app screen" />
          <img className="absolute w-p-100 dynamic-screen" src={screen2} alt="Math Arena app screen" />
          <img className="absolute w-p-100 dynamic-screen" src={screen1} alt="Math Arena app screen" />
        </div>
      </div>
    </div>
  </article>
);

export default Ranking;
