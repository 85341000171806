import React from "react";

import "../../styles/design-badges.css";

import badge from "../../images/math-arena-badge-scheme.png";
import opBadges from "../../images/badges_operation.png";
import podiumBadges from "../../images/badges_podium.png";
import rankingBadges from "../../images/badges_ranking.png";

const Badges = () => (
  <article className="js-badges mxw-690-nl mh-auto pt-l-nl pb-xl-l tc-nl fadeup">
    <div className="flex-l items-center mb-xl td-80 fadeup__item">
      <div className="w-p-100 w-p-50-l pr-xl-l">
        <h2 className="mb-l f3 f2-l fw-300">Badges</h2>
        <p className="mb-l f-text f4-l lh1 tc-grey-light">
          The best way to encourage users to keep practicing and improving is to award them for their efforts. With that in
          mind, badges that users collect as they progress through the different levels or through the ranking ladder, were
          designed.
          <br />
          <br />
          The collection is composed by a badge for each level of each operation type, one for the ranking’s top 50 and top 10
          and finally one for each podium position in the ranking.
          <br />
          <br />
          Each badge is composed by a shield; decorative elements referring to its level; text to identify the level; a symbol
          and a color to identify the operation type.
        </p>
      </div>
      <div className="w-p-100 w-p-50-l mh-auto-s pt-xl-l tc">
        <img className="w-p-100 mxw-380" src={badge} alt="Badge design scheme" />
      </div>
    </div>
    <div className="flex-l justify-around f-text td-80 fadeup__item">
      <div className="mb-l-nl tc-nl">
        <img className="mb-m cs-related-badges" src={opBadges} alt="Operation related badges" />
        <p className="f5-s">Operation related</p>
      </div>
      <div className="mb-l-nl tc-nl">
        <img className="mb-m cs-related-badges" src={podiumBadges} alt="Podium related badges" />
        <p className="f5-s">Podium related</p>
      </div>
      <div className="tc-nl">
        <img className="mb-m cs-related-badges" src={rankingBadges} alt="Ranking related badges" />
        <p className="f5-s">Ranking related</p>
      </div>
    </div>
  </article>
);

export default Badges;
