import React from "react";

import "../../styles/code.css";

import apollo from "../../images/apollo.png";
import graphql from "../../images/graphql.png";
import mongo from "../../images/mongodb.png";

const CodeServer = () => (
  <article className="js-server mxw-690-nl mh-auto pv-l-nl pv-xl-l tc-nl fadeup">
    <div className="flex-l flex-row-inverse-l justify-center items-center td-80 fadeup__item">
      <div className="w-p-100 w-p-50-l mb-l-s mb-xl-m tc-nl">
        <h2 className="mb-l f3 f2-l fw-300">Apollo, GraphQL and Mongo DB</h2>
        <p className="f-text f4-l lh1 tc-grey-light">
          In a first version of the app, all the data related to the user's scores and levels was stored locally, using{" "}
          <a
            href="https://facebook.github.io/react-native/docs/asyncstorage"
            className="tc-red u-default"
            target="_blank"
            rel="noopener noreferrer"
          >
            AsyncStorage
          </a>
          . But in the 2.0 version the app included a general ranking screen and users could also visit each other's profile
          page. So it was necessary to have a general data base and a server to manage all the information.
          <br />
          <br />
          The choice of{" "}
          <a href="https://graphql.org/" className="tc-red u-default" target="_blank" rel="noopener noreferrer">
            GraphQL
          </a>{" "}
          was made based on the simplicity of its query structure. The fact that it returns a javascript object to a single{" "}
          <code className="tc-grey-dark">/graphql</code> endpoint, made it really easy to implement with the existing react
          native code, after the necessary schema was defined.
          <br />
          <br />
          The database was created using{" "}
          <a href="https://www.mongodb.com/cloud/atlas" className="tc-red u-default" target="_blank" rel="noopener noreferrer">
            MogoDB atlas
          </a>
          , and an{" "}
          <a href="https://www.apollographql.com/" className="tc-red u-default" target="_blank" rel="noopener noreferrer">
            Apollo Server
          </a>{" "}
          was set up in order to query the data base for the user's session data, levels, profile info and of corse to save game
          scores and ranking points.
        </p>
      </div>
      <div className="w-p-100 w-p-50-l mh-auto-s pr-xl-l tc">
        <ul className="flex items-center justify-around">
          <li className="">
            <img className="mb-m va-middle cs-code-logo" src={apollo} alt="Apollo Server logo" />
            <p className="f4-ns tc-apollo">Apollo Server</p>
          </li>
          <li className="">
            <img className="mb-m va-middle cs-code-logo" src={graphql} alt="GraphQl logo" />
            <p className="f4-ns tc-graph">GraphQl</p>
          </li>
          <li className="">
            <img className="mb-m va-middle cs-code-logo" src={mongo} alt="MongoDB logo" />
            <p className="f4-ns tc-mongo">MongoDB</p>
          </li>
        </ul>
      </div>
    </div>
  </article>
);

export default CodeServer;
