import React from "react";
import { levels } from "../../../Utils/appVars";

import levels_1 from "../../../images/game-levels-01.png";
import levels_2 from "../../../images/game-levels-02.png";
import levels_3 from "../../../images/game-levels-03.png";
import levels_4 from "../../../images/game-levels-04.png";
import levels_5 from "../../../images/game-levels-05.png";
const iconsArr = [levels_1, levels_2, levels_3, levels_4, levels_5];

const ScreenLevel = ({ color, setOpLevel, nextScreen }) => (
  <section className="flex flex-column justify-center items-center w-p-100">
    <h3 className="f3 f2-ns fw-100 tc-grey-light mb-l">Select your skill level:</h3>
    <ul>
      {levels.map((v, i) => (
        <li key={i} className="mb-m">
          <button
            title={`${v} level`}
            onClick={() => {
              setOpLevel(v);
              nextScreen();
            }}
            className={`flex justify-between bd-color-${color} td-40 op-btn`}
          >
            <p className="ph-m tc-grey-light f4 fw-600 capitalize op-btn__label">{v}</p>
            <div className={`flex items-center justify-center bd-color-${color} op-btn__level--icon`}>
              <img src={iconsArr[i]} alt={`${v} level`} />
            </div>
          </button>
        </li>
      ))}
    </ul>
  </section>
);

export default ScreenLevel;
