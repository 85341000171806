import React from "react";

import "../../styles/design-logo.css";

import logo from "../../images/math-arena-logo.png";
import logoBig from "../../images/math-arena-logo-big.png";
import appIcon from "../../images/math-arena-app-logo.png";
import prevLogos1 from "../../images/math-arena-previous-logos-1.png";
import prevLogos2 from "../../images/math-arena-previous-logos-2.png";
import prevLogos3 from "../../images/math-arena-previous-logos-3.png";
import prevLogos4 from "../../images/math-arena-previous-logos-4.png";
import prevLogos5 from "../../images/math-arena-previous-logos-5.png";

const Logo = () => (
  <article className="js-logo mxw-690-nl mh-auto pv-l-nl pv-xl-l section-border-s tc-nl fadeup">
    <div className="flex-l flex-row-inverse-l justify-center items-center mb-xl td-80 fadeup__item">
      <div className="w-p-100 w-p-50-l mb-l-nl tc-nl">
        <h2 className="mb-l f3 f2-l fw-300">Logo</h2>
        <p className="f-text f4-l lh1 tc-grey-light">
          For the logo, the idea was to have some mathematical reference, but without letting it take over the entire logo. Some
          first iterations included all the colors of the different operations and others had references to an abacus.
          <br /> In the end the choice was to use square brackets to encapsulate the “a” and a serif font for the “M” to give it
          the same look as a mathematical formula. With dark grey and red as colors.
        </p>
      </div>
      <div className="w-p-100 w-p-50-l mh-auto-s pr-xl-l tc">
        <ul className="flex items-center justify-around f-text tc-grey-light">
          <li className="pr-l-s w-p-25-s">
            <div className="cs-logos-container mb-m">
              <img className="w-p-100 va-middle cs-logo-icon" src={appIcon} alt="Math Arena app icon" />
            </div>
            <p className="f5-s">Icon</p>
          </li>
          <li className="w-p-50-s pr-m-s">
            <div className="cs-logos-container mb-m">
              <img className="w-p-100 va-middle cs-logo-big" src={logoBig} alt="Math Arena logo big" />
            </div>
            <p className="f5-s">Logo big</p>
          </li>
          <li className="w-p-25-s">
            <div className="cs-logos-container mb-m">
              <img className="w-p-100 va-middle cs-logo-small" src={logo} alt="Math Arena logo" />
            </div>
            <p className="f5-s">Logo small</p>
          </li>
        </ul>
      </div>
    </div>
    <div className="mh-auto tc-nl td-80 fadeup__item cs-prev-logos">
      <p className="mb-m f-text f4">Previous iterations:</p>
      <ul className="flex justify-center justify-around-l flex-wrap">
        <li className="mh-m mb-s">
          <img className="cs-prev-logo" src={prevLogos1} alt="Previous logo iterations" />
        </li>
        <li className="mh-m mb-s">
          <img className="cs-prev-logo" src={prevLogos2} alt="Previous logo iterations" />
        </li>
        <li className="mh-m mb-s">
          <img className="cs-prev-logo" src={prevLogos3} alt="Previous logo iterations" />
        </li>
        <li className="mh-m mb-s">
          <img className="cs-prev-logo" src={prevLogos4} alt="Previous logo iterations" />
        </li>
        <li className="mh-m mb-s">
          <img className="cs-prev-logo" src={prevLogos5} alt="Previous logo iterations" />
        </li>
      </ul>
    </div>
  </article>
);

export default Logo;
