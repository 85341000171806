import React from "react";
import logo from "../../images/math-arena-app-logo.png";
import android from "../../images/play-store.png";
import ios from "../../images/app-store.png";

const Cta = () => (
  <section className="bg-grey-light">
    <article className="pv-xl ph-m tc-grey-light tc">
      <h2 className="relative dib mh-auto mb-l pb-m-l f3 f2-l fw-300-ns tc u-regular-title">
        Want to improve your mental math skills?
      </h2>
      <div className="flex items-center justify-center mb-l mb-xl-l">
        <img className="h-64 mb-l-s mr-m" src={logo} alt="Math Arena app Logo" />
        <p className="mxw-380 f-text f4-l tl tc-grey-light">
          Download the app for <strong>free</strong> and have fun becoming a calculations Grandmaster.
        </p>
      </div>
      <div className="flex justify-center mt-xl">
        <a
          href="https://play.google.com/store/apps/details?id=com.matharenalr"
          target="blank"
          rel="noopener noreferrer nofollow"
          className="h-42 h-50-l mr-m"
        >
          <img className="mxh-p-100" src={android} alt="Download Math Arena on Play Store" />
        </a>
        <a
          href="https://apps.apple.com/us/app/math-arena/id1451707900?l=pt&ls=1"
          target="blank"
          rel="noopener noreferrer nofollow"
          className="h-42 h-50-l"
        >
          <img className="mxh-p-100" src={ios} alt="Download Math Arena on App Store" />
        </a>
      </div>
    </article>
  </section>
);

export default Cta;
