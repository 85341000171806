import React, { Component } from "react";
import "../../styles/cover.css";
import cover_phone from "../../images/math-arena-case-study-cover.png";

class Cover extends Component {
  state = {
    active: false
  };

  componentDidMount() {
    this.setState({ active: true });
  }

  render() {
    const { active } = this.state;

    return (
      <section className="bg-red-gradient tc-white of-hidden">
        <div className="relative flex-ns items-center items-end-nl mxw-1212 vh-100 mxh-640 mh-auto ph-m cover">
          <h1 className="mb-xl-s mb-xxl-m pt-l-s pt-xl-l pl-l-m f1 fw-300 tc-s cs-cover__title">
            Math Arena <span className="db fw-600"> Case Study </span>
          </h1>
          <img
            className={`absolute w-p-100 td-60 cs-cover__image ${active && "active"}`}
            src={cover_phone}
            alt="Math Arena iPhone screens"
          />
        </div>
      </section>
    );
  }
}

export default Cover;
