import React, { Component } from "react";
import ScreenType from "./ScreenType";
import ScreenLevel from "./ScreenLevel";
import ScreenPreGame from "./ScreenPreGame";
import ScreenGame from "./ScreenGame";
import ScreenResume from "./ScreenResume";

import { operations, colors } from "../../../Utils/appVars";

import "../../../styles/modal.css";

class Modal extends Component {
  state = {
    screen: 1,
    type: "",
    level: "",
    color: "",
    points: 0
  };

  nextScreen() {
    const { screen } = this.state;
    this.setState({ screen: screen + 1 });
  }

  prevScreen() {
    const { screen } = this.state;
    this.setState({ screen: screen - 1 });
  }

  setOpType(type) {
    const colorIndex = operations.indexOf(type);
    this.setState({ type, color: colors[colorIndex] });
  }

  setOpLevel(level) {
    this.setState({ level });
  }

  setPoints(points) {
    this.setState({ points });
  }

  clearGameData() {
    setTimeout(() => {
      this.setState({
        screen: 1,
        type: "",
        level: "",
        color: "",
        points: 0
      });
    }, 600);
  }

  renderScreen() {
    const { screen, type, level, color, points } = this.state;
    const nextScreen = this.nextScreen.bind(this);

    switch (screen) {
      case 2:
        return <ScreenLevel color={color} setOpLevel={this.setOpLevel.bind(this)} nextScreen={nextScreen} />;
      case 3:
        return <ScreenPreGame type={type} level={level} color={color} nextScreen={nextScreen} />;
      case 4:
        return (
          <ScreenGame type={type} level={level} color={color} nextScreen={nextScreen} setPoints={this.setPoints.bind(this)} />
        );
      case 5:
        return <ScreenResume points={points} playAgain={this.clearGameData.bind(this)} />;
      default:
        return <ScreenType setOpType={this.setOpType.bind(this)} nextScreen={nextScreen} />;
    }
  }

  render() {
    const { screen } = this.state;
    const { gameOn, toggleGameModal } = this.props;

    return (
      <section
        className={`fixed top-0 left-0 z-3 flex items-center justify-center ${
          gameOn ? "vh-100" : "h-0"
        } w-p-100 overlay of-hidden td-60`}
      >
        <article className="relative flex w-p-100 pv-xl of-hidden mxw-690 modal-container">
          <button
            onClick={() => {
              this.clearGameData();
              toggleGameModal();
            }}
            className="absolute db icon-close"
          />
          {this.renderScreen()}
          {screen < 4 && <p className="absolute fw-300 modal-pagination">{screen}/3</p>}
        </article>
      </section>
    );
  }
}

export default Modal;
