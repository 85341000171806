import React from "react";

import "../../styles/code.css";

import reactNative from "../../images/react-native.png";
import redux from "../../images/redux.png";

const CodeLanguage = () => (
  <article className="js-codeIntro mxw-690-nl mb-xl-m mh-auto pv-l-nl pv-xl-l section-border-s tc-nl fadeup">
    <div className="flex-l flex-column-s items-center">
      <div className="w-p-100 w-p-50-l mb-l-s mb-xl-m pr-xl-l td-80 fadeup__item">
        <h2 className="mb-l f3 f2-l fw-300">React Native and Redux</h2>
        <p className="mb-l f-text f4-l lh1 tc-grey-light">
          This app was built using{" "}
          <a
            href="https://facebook.github.io/react-native/"
            className="tc-red u-default"
            target="_blank"
            rel="noopener noreferrer"
          >
            React Native
          </a>
          . The main reason for this decision is simple: with react native it’s possible to create an app for iOS and Android at
          the same time. Not only making it much quicker to launch, but also allowing the use of only one code base. Which is
          helpful when it comes to code maintenance, like doing updates or bug corrections. In addition,{" "}
          <a href="https://redux.js.org/" className="tc-red u-default" target="_blank" rel="noopener noreferrer">
            Redux library
          </a>{" "}
          was used to manage the flow of information inside the app's react components.
        </p>
      </div>
      <div className="w-p-100 w-p-50-l mxw-400 mh-auto tc td-80 fadeup__item">
        <ul className="flex items-center justify-around">
          <li className="">
            <img className="mb-m va-middle cs-code-logo" src={reactNative} alt="React Native logo" />
            <p className="f4 f3-ns tc-react">React Native</p>
          </li>
          <li className="">
            <img className="mb-m va-middle cs-code-logo" src={redux} alt="Redux logo" />
            <p className="f4 f3-ns tc-redux">Redux</p>
          </li>
        </ul>
      </div>
    </div>
  </article>
);

export default CodeLanguage;
