import React from "react";
import ranking from "../../images/ranking.png";

const Ranking = () => (
  <article className="js-ranking pv-l pv-xl-l section-border-s fadeup">
    <div className="flex-ns flex-column-s items-center">
      <div className="flex justify-center w-p-100 w-p-50-ns pr-l-m td-80 fadeup__item">
        <div className="mxw-400 tc-s">
          <h2 className="mb-l f3 f2-l fw-300">See where you rank amongst the best.</h2>
          <p className="mxw-400 mb-l f-text f4-l lh1 tc-grey-light">
            If you are already better than anyone you know in the real world at mental calculations, you can compare yourself to
            other players in the app and see where you rank amongst the best in the world.
          </p>
        </div>
      </div>
      <div className="w-p-100 w-p-50-ns mh-auto-s tc td-80 fadeup__item">
        <img className="w-p-100 mxw-380" src={ranking} alt="Math Arena ranking" />
      </div>
    </div>
  </article>
);

export default Ranking;
