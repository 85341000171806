import React from "react";

import { operations, colors } from "../../../Utils/appVars";

import icon_sum from "../../../images/game-sum_icon.svg";
import icon_subtraction from "../../../images/game-subtraction_icon.svg";
import icon_multiplication from "../../../images/game-multiplication_icon.svg";
import icon_division from "../../../images/game-division_icon.svg";
import icon_percentage from "../../../images/game-percentage_icon.svg";
const iconsArr = [icon_sum, icon_subtraction, icon_multiplication, icon_division, icon_percentage];

const ScreenType = ({ setOpType, nextScreen }) => {
  return (
    <section className="flex flex-column justify-center items-center w-p-100">
      <h3 className="f3 f2-ns fw-100 tc-grey-light mb-l">Select the operation type:</h3>
      <ul>
        {operations.map((v, i) => (
          <li key={i} className="mb-m">
            <button
              title={v}
              onClick={() => {
                setOpType(v);
                nextScreen();
              }}
              className={`flex justify-between bd-color-${colors[i]} td-40 op-btn`}
            >
              <p className="ph-m tc-grey-light f4 fw-600 capitalize op-btn__label">{v}</p>
              <div className={`bd-color-${colors[i]} op-btn__icon`}>
                <img src={iconsArr[i]} alt={`${v} icon`} />
              </div>
            </button>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ScreenType;
