import React from "react";

import android from "../../../images/play-store.png";
import ios from "../../../images/app-store.png";

const ScreenResume = ({ points, playAgain }) => {
  const titleArr = ["Excelent!", "Good Result.", "You’ll have to try harder."];
  const copyArr = [
    "You clearly got what it takes to challenge the best ones out there!\n Try the app and show the world your abilities.",
    "You still have margin to improve.\n Download the app, practice, and you'll be fighting for the podium positions in no time!",
    "Arithmetic isn't your thing?\n No problem!\n Download the app and start practicing now.\n We will help you impress your friends, and yourself!"
  ];
  let numberColor, title, copy;

  if (points >= 80) {
    numberColor = "#336643";
    title = titleArr[0];
    copy = copyArr[0];
  } else if (points < 50) {
    numberColor = "#cc5252";
    title = titleArr[2];
    copy = copyArr[2];
  } else {
    numberColor = "#f7931e";
    title = titleArr[1];
    copy = copyArr[1];
  }

  return (
    <section className="flex flex-column justify-center items-center w-p-100">
      <h4 className="f2 fw-100 tc-grey-light mb-l">{title}</h4>
      <div className="flex items-center flex-column-reverse-s pv-l resume-box">
        <div className="w-p-100 w-p-40-ns pv-l tc-grey-light tc bd-r-1-grey-light">
          <p className="mb-m f4">Your score:</p>
          <p className="f3 fw-600" style={{ color: numberColor }}>
            {points}
          </p>
          <p className="mb-l fw-600">Points</p>
          <button
            title="play again"
            onClick={() => playAgain()}
            className="db mh-auto bg-red tc-white f5 fw-600 uppercase td-40 btn"
          >
            Play Again
          </button>
        </div>
        <div className="w-p-100 w-p-60-ns ph-l">
          <p className="mb-l tl fw-300 f4 tc-s">{copy}</p>
          <div className="flex items-center justify-center-s">
            <a
              href="https://play.google.com/store/apps/details?id=com.matharenalr"
              target="blank"
              rel="noopener noreferrer nofollow"
              className="h-42 mr-m"
            >
              <img className="mxh-p-100" src={ios} alt="Download Math Arena on Play Store" />
            </a>
            <a
              href="https://apps.apple.com/us/app/math-arena/id1451707900?l=pt&ls=1"
              target="blank"
              rel="noopener noreferrer nofollow"
              className="h-42"
            >
              <img className="mxh-p-100" src={android} alt="Download Math Arena on App Store" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScreenResume;
