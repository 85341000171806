import React from "react";
import "../../styles/design-typo-colors.css";

const colors = ["#cc7171", "#e59661", "#ccc07a", "#5f9e78", "#628ca3", "#705b87"];

const renderColors = () => {
  return colors.map((v, i) => (
    <li key={i} className="mh-m-nl mb-m-nl">
      <span className="db mh-auto mb-m bd-round color-ball" style={{ backgroundColor: v }} />
      <p className="f6-s f5">{v}</p>
    </li>
  ));
};

const TypoColors = () => {
  return (
    <article className="js-typoColors mxw-690-nl mh-auto pv-l-nl pb-xl-l section-border-s tc-nl fadeup">
      <div className="flex-l items-center">
        <div className="w-p-100 w-p-50-l pr-xl-l td-80 fadeup__item">
          <h2 className="mb-l f3 f2-l fw-300">Colors and Typography</h2>
          <p className="mb-l f-text f4-l lh1 tc-grey-light">
            Although the main color of the app is red, it was necessary that each type of operation had its own identity, to
            make it easier for users to know where they were on the app. So each operation type got it's own color and all the
            colors are in pastel tones in order to keep a soft visual.
          </p>
          <p className="dn-nl mb-l f-text f4-l lh1 tc-grey-light">
            To keep a clean look on the app, the chosen font was SF Pro Display.
          </p>
        </div>
        <div className="w-p-100 w-p-50-l mh-auto-s pt-xl-l tc td-80 fadeup__item">
          <ul className="flex justify-center justify-around-l flex-wrap mb-xl">{renderColors()}</ul>
          <p className="dn-l mb-l f-text f4-l lh1 tc-grey-light">
            To keep a clean look on the app, the chosen font was SF Pro Display.
          </p>
          <div className="f4-m f3-l tc-grey-light">
            <p className="mb-s">ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
            <p className="mb-s">abcdefghijklmnopqrstuvwxyz</p>
            <p className="">{`1234567890’?\`”!()%[]{}+-/*=<>,.:;`}</p>
          </div>
        </div>
      </div>
    </article>
  );
};

export default TypoColors;
