import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navigation from "./Components/Navigation";
import Home from "./Components/Home/";
import CaseStudy from "./Components/CaseStudy";
import Footer from "./Components/Footer";

class App extends Component {
  state = {
    bgRed: false,
    topsArr: [],
    sections: []
  };
  allScrolled = false;

  componentDidUpdate() {
    window.addEventListener("scroll", this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  setSections(sectionsArr) {
    this.setState({ sections: sectionsArr });
  }

  setTopsArr(arr) {
    this.setState({ topsArr: arr });
  }

  handleScroll = () => {
    const { bgRed, topsArr, sections } = this.state;
    const scrollOffset = window.scrollY;

    if (!bgRed && scrollOffset > 100) {
      this.changeNavBg();
    } else if (bgRed && scrollOffset < 100) {
      this.changeNavBg();
    }

    if (!this.allScrolled) {
      topsArr.forEach((v, i) => {
        const section = document.querySelector(sections[i]);
        if (!section.classList.contains("opened") && scrollOffset + window.innerHeight > v) {
          section.className += " opened";
          this.allScrolled = i === topsArr.length;
        }
      });
    }
  };

  changeNavBg() {
    const { bgRed } = this.state;
    this.setState({ bgRed: !bgRed });
  }

  render() {
    const { bgRed } = this.state;

    return (
      <Router>
        <Navigation bgRed={bgRed} />
        <Switch>
          <Route path="/case-study">
            <CaseStudy setTopsArr={this.setTopsArr.bind(this)} setSections={this.setSections.bind(this)} />
          </Route>
          <Route path="/">
            <Home setTopsArr={this.setTopsArr.bind(this)} setSections={this.setSections.bind(this)} />
          </Route>
        </Switch>
        <Footer />
      </Router>
    );
  }
}

export default App;
