import React, { Component } from "react";
import Cover from "./Cover";
import Intro from "./Intro";
import Game from "./Game";
import Ranking from "./Ranking";
import Trophies from "./Trophies";
import CaseStudy from "./CaseStudy";
import Cta from "./Cta";

import "../../styles/fadeup.css";

class Home extends Component {
  componentDidMount() {
    const { setTopsArr, setSections } = this.props;
    const windowHeight = window.innerHeight;
    const tops = [];
    const sections = [".js-intro", ".js-game", ".js-ranking", ".js-trophies", ".js-case-study"];

    sections.forEach((v, i) => {
      tops.push(document.querySelector(v).getBoundingClientRect().top + windowHeight / 2);
    });

    setTopsArr(tops);
    setSections(sections);
  }

  render() {
    return (
      <main>
        <Cover />
        <section className="mxw-1212 mh-auto pv-xl ph-m of-hidden tc-grey-dark">
          <Intro />
          <Game />
          <Ranking />
          <Trophies />
          <CaseStudy />
        </section>
        <Cta />
      </main>
    );
  }
}

export default Home;
